<template>
  <div class="h-100 w-100 bg-white">
      <div class="container">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";

export default defineComponent({
  name: "registration",
  computed: {
    registrationData() {
      return store.getters.registrationInfo;
    }
  },
  async mounted() {
    if (!store.getters.isUserAuthenticated) {
      redirectToRouteWithName('sign-in');
    }
  }
});
</script>
<style scoped lang="scss">

</style>
